import _, { throttle } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { BASE_ZINDEX } from '../popup-factory';

import { closePopup, createSpinnerPopup } from '../actions/popup';
import { createCheckout } from '../actions/shop';

import { verifyCard, parseObjectIntoUrlString, filterCreditcardValues } from '../utils';

import Form from './Form';
import { withRouter } from './helpers';

const DEFAULT_COLOR = '#5ca3b6';

class CardConnectPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            country: '',
            error: '',
            captcha: '',
        };

        _.bindAll(this, ['onFormRef', 'onPayByCreditCard', 'handleFieldChange']);
        this.cardconnectTokenize = throttle(this.cardconnectTokenize.bind(this), 5000, {trailing: false});
    }

    onFormRef(form) {
        this._form = form;
    }

    handleFieldChange(value, field) {
        const data = { [field]: value };
        if (field === 'country') {
            data.state = '';
        }
        this.setState(data);
    }

    validateCreditCardFields(values) {
        const { email, cvv, country, postal, credit_card } = values;

        if(!/\S+@\S+\.\S+/.test(email)) {
            this.setState({ error: 'Email appears to be invalid' });
            return false;
        }
        if(cvv.length < 3 || cvv.length > 4) {
            this.setState({ error: 'Security code appears to be invalid' });
            return false;
        }
        if ((country === 'US' && !postal.match(/^\d{5}([\-]?\d{4})?$/)) || (country === 'CA' && !postal.toUpperCase().match(/^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/))) {
            this.setState({ error: 'Zip Code appears to be invalid' });
            return false;
        }
        if (!verifyCard(credit_card)) {
            this.setState({ error: 'Credit card number appears to be invalid' });
            return false;
        }

        return true;
    }

    onPayByCreditCard(values, errors) {
        if (!_.every(errors, (error) => error === null)) {
            return;
        }

        try {
            if (!this.validateCreditCardFields(values)) {
                return;
            }
            this.cardconnectTokenize(values);
        } catch (TokenError) {
            console.log(TokenError);
            this.setState({ error: TokenError.message });
        }
    }

    cardconnectTokenize(values) {
        const { credit_card } = values;
        const { shop, onCheckout, checkoutTotal } = this.props;
        const filteredValues = filterCreditcardValues(values);

        let formatted_credit_card = credit_card.replace(/-| /g, '');
        let url = shop.company_data.cardconnect_api_url;
        // some clients has issue with https://fts.cardconnect.com:8443, they will get a timeout error
        // switch them to https://fts.cardconnect.com:443 to get a more stable result
        // !!! WE DONT WANT TOUCH https://smartpay.prinpay.com:8443, it will return a different token !!!
        if (url === 'https://fts.cardconnect.com:8443') {
            url = 'https://fts.cardconnect.com';
        }

        const _this = this;
        fetch(url + "/cardsecure/cs?" + parseObjectIntoUrlString({ action: 'CE', data: formatted_credit_card }),
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/javascript',
                    'Content-Type': 'application/javascript'
                }
            })
            .then(res => res.text())
            .then(
                (result) => {
                    let resultObj = JSON.parse('{"' + decodeURI(result.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');

                    if (resultObj.action == 'ER') {
                        console.log('Credit card number appears to be invalid');
                    } else {
                        onCheckout({
                            ...filteredValues,
                            credit_card_software: shop.company_data.credit_card_software,
                            token: resultObj.data,
                            company_id: shop.tenant_id,
                            parent_type: shop.aggregate == 0 ? 'INVOICE' : 'PARTIAL',
                            description: `Shop checkout from ${shop.shop_name}`,
                            amount: checkoutTotal,
                            currency: shop.currency_id
                        }, this.state.captcha, this.props.navigate);
                    }
                },
                (error) => {
                    console.log(error);
                    _this.setState({ error: 'Failed to get CardConnect Token.' });
                }
            );
    }

    render() {
        const { shop, checkoutTotal, onClosePopup, index } = this.props;
        const template_color = shop.template_color || DEFAULT_COLOR;
        const countries = [
            { key: 'US', value: 'United States' },
            { key: 'CA', value: 'Canada' },
            { key: 'AR', value: 'Argentina' },
            { key: 'AU', value: 'Australia' },
            { key: 'BE', value: 'Belgium' },
            { key: 'BM', value: 'Bermuda' },
            { key: 'BR', value: 'Brazil' },
            { key: 'KY', value: 'Cayman Islands' },
            { key: 'CL', value: 'Chile' },
            { key: 'DK', value: 'Denmark' },
            { key: 'DC', value: 'District of Columbia' },
            { key: 'DO', value: 'Dominican Republic' },
            { key: 'FR', value: 'France' },
            { key: 'DE', value: 'Germany' },
            { key: 'IL', value: 'Israel' },
            { key: 'IT', value: 'Italy' },
            { key: 'JM', value: 'Jamaica' },
            { key: 'JP', value: 'Japan' },
            { key: 'PR', value: 'Puerto Rico' },
            { key: 'KR', value: 'Republic of Korea' },
            { key: 'SG', value: 'Singapore' },
            { key: 'ES', value: 'Spain' },
            { key: 'TH', value: 'Thailand' },
            { key: 'TT', value: 'Trinidad and Tobago' },
            { key: 'AE', value: 'United Arab Emirates' },
            { key: 'GB', value: 'United Kingdom' }
        ];
        const month_options = [
            { key: "01", value: "01 - January" },
            { key: "02", value: "02 - February" },
            { key: "03", value: "03 - March" },
            { key: "04", value: "04 - April" },
            { key: "05", value: "05 - May" },
            { key: "06", value: "06 - June" },
            { key: "07", value: "07 - July" },
            { key: "08", value: "08 - August" },
            { key: "09", value: "09 - September" },
            { key: "10", value: "10 - October" },
            { key: "11", value: "11 - November" },
            { key: "12", value: "12 - December" }
        ];
        const current_year = new Date().getFullYear();
        const year_options = [];
        for (var i = current_year; i < current_year + 20; i++) {
            year_options.push({ key: ('0' + i).slice(-2), value: ('0' + i).slice(-2) });
        }
        const states = ["Alabama (AL)", "Alaska (AK)", "Arizona (AZ)", "Arkansas (AR)", "California (CA)", "Colorado (CO)", "Connecticut (CT)", "Delaware (DE)", "District of Columbia (DC)", "Florida (FL)", "Georgia (GA)", "Hawaii (HI)", "Idaho (ID)", "Illinois (IL)", "Indiana (IN)", "Iowa (IA)", "Kansas (KS)", "Kentucky (KY)", "Louisiana (LA)", "Maine (ME)", "Maryland (MD)", "Massachusetts (MA)", "Michigan (MI)", "Minnesota (MN)", "Mississippi (MS)", "Missouri (MO)", "Montana (MT)", "Nebraska (NE)", "Nevada (NV)", "New Hampshire (NH)", "New Jersey (NJ)", "New Mexico (NM)", "New York (NY)", "North Carolina (NC)", "North Dakota (ND)", "Ohio (OH)", "Oklahoma (OK)", "Oregon (OR)", "Pennsylvania (PA)", "Rhode Island (RI)", "South Carolina (SC)", "South Dakota (SD)", "Tennessee (TN)", "Texas (TX)", "Utah (UT)", "Vermont (VT)", "Virginia (VA)", "Washington (WA)", "West Virginia (WV)", "Wisconsin (WI)", "Wyoming (WY)"];
        const state_options = states.map(s => ({ key: s, value: s }));
        const provinces = ["Alberta (AB)", "British Columbia (BC)", "Manitoba (MB)", "New Brunswick (NB)", "Newfoundland and Labrador (NL)", "Northwest Territories (NT)", "Nova Scotia (NS)", "Nunavut (NU)", "Ontario (ON)", "Prince Edward Island (PE)", "Quebec (QC)", "Saskatchewan (SK)", "Yukon Territory (YT)"];
        const province_options = provinces.map(p => ({ key: p, value: p }));

        return (
            <div className="shop" style={{ zIndex: BASE_ZINDEX + index, position: 'absolute' }}>
                <div id="popup-background">
                    {!shop.company_data.cardconnect_live_mode ? <div style={Styles.test_mode}>TEST MODE</div> : null}
                    <div className="popup-content" style={{ padding: '0px 50px 0px 50px' }}>
                        <div className="popup-cardconnect">
                            {this.state.error !== '' && <p style={{ color: 'red' }}>{this.state.error}</p>}
                            <a className="close-order" onClick={onClosePopup}>×</a>
                            <Form className="row popup-content form"
                                ref={this.onFormRef}
                                onSubmit={this.onPayByCreditCard}
                                style={{ overflowY: 'unset', overflow: 'visible' }}
                                onFieldChange={this.handleFieldChange}
                            >
                                <Form.TextInput data-testid="cc-name" label="Name" field="name" value={this.state.name} required={true} />
                                <Form.TextInput data-testid="cc-email" label="Email" field="email" value={this.state.email} required={true} />
                                <Form.Select data-testid="cc-country" label="Country" field="country" options={countries} value={this.state.country} required={true} withMarginBottom />
                                <Form.TextInput data-testid="cc-address" label="Address" field="address" value={this.state.address} required={true} />
                                {this.state.country === 'US' && <Form.Select data-testid="cc-state" label="State" field="state" options={state_options} value={this.state.state} required={true} withMarginBottom />}
                                {this.state.country === 'CA' && <Form.Select data-testid="cc-state" label="State" field="state" options={province_options} value={this.state.state} required={true} withMarginBottom />}
                                <Form.TextInput data-testid="cc-city" label="City" field="city" value={this.state.city} required={true} />
                                <Form.TextInput data-testid="cc-postal" label="Zip Code" field="postal" value={this.state.postal} required={true} />
                                <Form.TextInput data-testid="cc-credit_card" label="Credit Card" field="credit_card" value={this.state.credit_card} required={true} />
                                <Form.TextInput data-testid="cc-cvv" label="CVV" helpText="3-4 digit number on back of card" field="cvv" value={this.state.cvv} required={true} />
                                <Form.Select data-testid="cc-expiry_month" label="Expiry Month" field="expiry_month" options={month_options} value={this.state.expiry_month} required={true} withMarginBottom />
                                <Form.Select data-testid="cc-expiry_year" label="Expiry Year" field="expiry_year" options={year_options} value={this.state.expiry_year} required={true} withMarginBottom />
				{!!shop.company_data.captcha_site_key && <div className="row">
					<div className="small-12 columns">
						<HCaptcha sitekey={shop.company_data.captcha_site_key} onVerify={(token, eKey) => this.setState({ captcha: token })} />
					</div>
				</div>}
                            </Form>
                            <div className="row">
			                    <div className="small-12 medium-4 columns"></div>
                                <div className="small-12 medium-8 columns">
                                    <button className="button" style={{ backgroundColor: template_color }} onClick={(e) => { e.preventDefault(); this._form && this._form.submit(); }}>{`Pay $${checkoutTotal} ${shop.currency_id}`}</button>
                                </div>
			                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClosePopup: () => dispatch(closePopup()),
    onCheckout: (payment_data, captcha=false, onRedirect=null) => {
        dispatch(closePopup());
        dispatch(createSpinnerPopup('Creating order...'));
        dispatch(createCheckout(
            ownProps.shop.shop_id,
            ownProps.billing_info,
            ownProps.shipping_info,
            ownProps.breakdowns,
            ownProps.artworks,
            ownProps.comment,
            payment_data,
            ownProps.shipping_range_id,
            ownProps.customer,
            ownProps.checkoutTotal,
            ownProps.order_metadata,
            ownProps.applied_discount,
            ownProps.customer_po,
            true,
            null,
            captcha,
            onRedirect
        ));
    },
});

const Styles = {
    test_mode: {
        position: 'absolute',
        color: '#fff',
        opacity: 0.75,
        top: '15px',
        left: '25px',
        fontSize: '25px'
    }
};

const ConnectedCardConnectPopup = withRouter(connect(null, mapDispatchToProps)(CardConnectPopup));
export default ConnectedCardConnectPopup;
